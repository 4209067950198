body {
  background-color: #000000;
}

* {
  box-sizing: border-box;
}

@keyframes Gallop {
  from {
    font-variation-settings: "TIME" 0;
  }
  to {
    font-variation-settings: "TIME" 15;
  }
}




.header {
  overflow: hidden;
  background-color: #000000;
  padding: 20px 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.badge {
  position: absolute;
  top: 20px;
  right: 100px;
  padding: 1px 8.5px;
  border-radius: 50%;
  background: red;
  color: white;
}
.header a {
  float: left;
  color: #ffffff;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  transition: 0.5s;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
  transition: 0.5s;
}

.header a:hover {
  background-color: rgb(41, 41, 41);
  transition: 0.5s;
}

.header a.active {
  background-color: #d0b47c;
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 812px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}

.loader {
  font: 320px Muybridge;
  animation: 0.6s linear infinite Gallop;
  align-items: center;
  align-content: center;
  text-align: center;
}

.loader-text {
  font-size: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  align-content: center;
  text-align: center;
}

.loader-text:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

footer {
  padding-top: 20px;
  padding-left: 20px;
  padding-left: 20px;
  padding-bottom: 18px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  background-color: #000000;
  position: absolute;
  width: 100%;
}

footer a {
  color: #ffffff;
  text-decoration: none;
}

.content {
  margin: 0;
  background-color: #d0b47c;
}

.href {
  color: #ffffff;
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #580c0c;
  color: white;
  text-align: center;
}
.footer p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media (max-width: 812px) {
  .badge {
    position: absolute;
    top: 325px;
    right: 125px;
    padding: 1px 8.5px;
    border-radius: 50%;
    background: red;
    color: white;
  }
  .header {
    overflow: hidden;
    text-align: center;
    background-color: #000000;
    padding: 20px 10px;
    padding-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .header a {
    text-align: center;
  }
  footer {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-left: 20px;
    font-size: 12px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    background-color: #000000;
    position: absolute;
    width: 100%;
  }
}
