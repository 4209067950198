h1 {
  font-family: "Times New Roman", Times, serif;
  font-size: 50px;
  margin-top: 0;
  padding-top: 40px;
  padding-left: 50px;
  font-weight: 80;
}

h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  padding-left: 55px;
  font-weight: 60;
}

.reitlaufbahn {
  padding-top: 25px;
  padding-left: 50px;
}

.reitlaufbahn p {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -275px;
  margin-left: 325px;
}

.pferdekontakt {
  padding-top: 100px;
  padding-left: 50px;
}

.p1 {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -200px;
  margin-left: 325px;
}

.p2 {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -0px;
  margin-left: 325px;
}

.p3 {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -0px;
  margin-left: 325px;
}

.p4 {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -0px;
  margin-left: 325px;
  padding-bottom: 30px;
}

@media (max-width: 812px) {
  h1 {
    font-family: "Times New Roman", Times, serif;
    font-size: 40px;
    padding-top: 40px;
    padding-left: 50px;
    font-weight: 80;
  }

  h2 {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    padding-left: 55px;
    font-weight: 60;
  }

  .reitlaufbahn img {
    height: 250px;
  }

  .pferdekontakt img {
    height: 210px;
  }

  .reitlaufbahn {
    padding-top: 25px;
    padding-left: 50px;
  }

  .reitlaufbahn p {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
    margin-left: -5px;
    padding-right: 20px;
  }

  .pferdekontakt {
    padding-top: 20px;
    padding-left: 50px;
  }

  .p1 {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
    margin-left: -5px;
    padding-right: 20px;
  }

  .p2 {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
    margin-left: -5px;
    padding-right: 20px;
  }

  .p3 {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
    padding-right: 20px;
    margin-left: -5px;
  }

  .p4 {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
    margin-left: -5px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}
