.formularo input {
  border-radius: 3px;
  padding-bottom: 20px;
  font-size: 20px;
  border-style: none;
}
#nachrichtarea {
  border-radius: 3px;
  padding-bottom: 225px;
  font-size: 10px;
  margin-left: 10px;
  border-style: none;
  padding-right: 235px;
}
.formularo h2 {
  margin-left: -55px;
}
.formularo p {
  padding-left: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.formularo h3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 33px;
  text-align: center;
}
.formularo h1 {
  padding-left: 65px;
}
.formularo label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.formo {
  padding-left: 70px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.dateinput {
  padding-right: 65px;
}
.formularo h4 {
  font-size: 35px;
  font-weight: 100;
  padding-left: 70px;
  font-family: "Times New Roman", Times, serif;
}
.formularo a[type="submit"] {
  text-decoration: none;
  color: black;
  border-width: 2px;
  border-radius: 4px;
  font-size: 25px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  margin-left: 500px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.formularo a[type="submit"]:hover {
  text-decoration: none;
  color: white;
  border-color: black;
  background-color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.required {
  color: red;
}

.warnung {
  margin-left: 60px;
  margin-right: 60px;
}

.requiredtexte {
  margin-left: 0px;
  padding-top: 10px;
  margin-bottom: -50px;
  font-style: italic;
}
#nachricht {
  padding-right: 190px;
  padding-bottom: 175px;
}
.vollbeendetlager p {
  font-size: 28px;
  padding-bottom: 30px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
@media (max-width: 812px) {
  #nachrichtarea {
    border-radius: 3px;
    padding-bottom: 120px;
    font-size: 10px;
    border-style: none;
    padding-right: 120px;
  }
  .formularo input {
    border-radius: 3px;
    padding-bottom: 20px;
    font-size: 20px;
    border-style: none;
  }
  .formularo h2 {
    margin-left: -55px;
  }
  .formularo p {
    padding-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .formularo h3 {
    font-family: "Times New Roman", Times, serif;
    font-size: 33px;
    text-align: center;
  }
  .formularo h1 {
    padding-left: 65px;
  }
  .formularo label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
  .formularo textarea {
    border-radius: 3px;
    padding-bottom: 20px;
    padding-right: 13px;
    border-style: none;
    font-size: 20px;
  }
  .formo {
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
  .dateinput {
    padding-right: 65px;
  }
  .formularo h4 {
    font-size: 35px;
    font-weight: 100;
    padding-left: 70px;
    font-family: "Times New Roman", Times, serif;
  }
  .formularo a[type="submit"] {
    text-decoration: none;
    color: black;
    border-width: 2px;
    border-radius: 4px;
    font-size: 25px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    margin-left: 500px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
    transition: 0.5s;
  }

  .formularo a[type="submit"]:hover {
    text-decoration: none;
    color: white;
    border-color: black;
    background-color: black;
    border-width: 2px;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
  }

  .required {
    color: red;
  }

  .requiredtext {
    margin-left: 0px;
    padding-top: 10px;
    margin-bottom: -50px;
    font-style: italic;
  }
  .requiredtext p {
    text-align: left;
    padding-top: 10px;
  }
  #nachricht {
    padding-right: 190px;
    padding-bottom: 175px;
  }
  .vollbeendetlager p {
    font-size: 20px;
    padding-bottom: 30px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}
