@import url("https://cdn.jsdelivr.net/npm/fork-awesome@1.1.7/css/fork-awesome.min.css");
.slide-container {
  margin-top: 0px;
}

.ubermich {
  text-align: center;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 10px;
  padding-bottom: 30px;
}
.ubermich h2 {
  font-family: "Times New Roman", Times, serif;
  color: #000000;
  font-weight: 80;
  font-size: 45px;
  padding-left: 0%;
}
.image-container img {
  margin-left: -50px;
  width: 2000px;
}

.ubermich a {
  text-decoration: none;
  color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.ubermich a:hover {
  text-decoration: none;
  color: white;
  border-color: black;
  background-color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.wiepferdeleben {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: 2000px;
  background-image: url("https://i.imgur.com/A2aOVJK.jpg");
  background-position-y: -470px;
}

.wiepferdeleben h1 {
  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  font-weight: 80;
  font-size: 45px;
  padding-top: 160px;
  padding-left: 50px;
}

.wiepferdeleben h2 {
  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  font-weight: 80;
  font-size: 40px;
  padding-left: 50px;
}

.wiepferdeleben p {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-weight: 60;
  margin-top: -140px;
  text-align: center;
  margin-bottom: 145px;
  padding-left: 800px;
  font-size: 20px;
}
.unserangebot {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.unserangebot p {
  padding-left: 20px;
  padding-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
.unserangebot::after {
  content: "";
  clear: both;
  display: table;
}
.unserangebot h1 {
  font-family: "Times New Roman", Times, serif;
  font-size: 50px;
  text-align: center;
  padding-top: 10px;
  font-weight: 80;
}

.unserangebot h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  text-align: center;
  font-weight: 80;
}

.unserangebot h3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  text-align: center;
  font-weight: 80;
}

.angebot-img {
  display: flex;
  justify-content: center;
}

.angebot-reitunterricht {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.angebot-reitunterricht img {
  height: 275px;
  margin: 0 10px;
}

.angebot-einstellplatz {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.angebot-einstellplatz img {
  height: 275px;
  margin: 0 10px;
}

.unserangebot a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.unserangebot a:hover {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: white;
  border-color: black;
  background-color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.kontakt {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: black;
}

.kontakt a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: white;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.kontakt a:hover {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: black;
  border-color: white;
  background-color: white;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.abhofverkaufhome {
  text-align: center;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 10px;
  padding-bottom: 30px;
}
.abhofverkaufhome h4 {
  font-family: "Times New Roman", Times, serif;
  color: #000000;
  font-weight: 80;
  font-size: 45px;
}
.abhofverkaufhome h3 {
  color: #000000;
  font-weight: 800;
  font-size: 25px;
  padding-bottom: 10px;
}

.abhofverkaufhome a {
  text-decoration: none;
  color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.abhofverkaufhome a:hover {
  text-decoration: none;
  color: white;
  border-color: black;
  background-color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.maps {
  padding-bottom: 30px;
}

.maps .embed {
  width: 800px;
  height: 600px;
  border-radius: 10px;
  border-style: none;
  box-shadow: 4px 4px 10px rgb(145, 145, 145);
}

@media (max-width: 812px) {
  .wiepferdeleben {
    height: 100vh;
    padding-right: 20px;
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: 1280px;
    background-image: url("https://i.postimg.cc/9Q22zcsM/Valeskini-Background-Leben.jpg");
    background-position-y: -250px;
    background-position-x: -400px;
  }

  .wiepferdeleben h1 {
    font-family: "Times New Roman", Times, serif;
    color: #ffffff;
    font-weight: 80;
    font-size: 30px;
    padding-top: 160px;
    padding-left: 0%;
    text-align: center;
  }

  .wiepferdeleben h2 {
    font-family: "Times New Roman", Times, serif;
    color: #ffffff;
    font-weight: 80;
    font-size: 25px;
    padding-left: 0%;
    text-align: center;
  }

  .wiepferdeleben p {
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    font-weight: 60;
    margin-top: 90px;
    text-align: center;
    margin-bottom: -80px;
    padding-left: 0px;
    font-size: 20px;
  }
  .image-container img {
    margin-left: -50px;
    height: 200px;
    width: 700px;
  }

  .unserangebot {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
  .unserangebot p {
    padding-left: 0%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  .unserangebot::after {
    content: unset;
    clear: unset;
    display: unset;
  }
  .unserangebot h1 {
    font-family: "Times New Roman", Times, serif;
    font-size: 50px;
    text-align: center;
    padding-left: 0%;
    padding-top: 10px;
    font-weight: 80;
  }

  .unserangebot h2 {
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    text-align: center;
    padding-left: 0%;
    font-weight: 80;
  }

  .unserangebot h3 {
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    text-align: center;
    font-weight: 80;
  }

  .angebot-img {
    display: unset;
    justify-content: center;
  }

  .angebot-reitunterricht {
    float: none;
    width: unset;
    padding-left: 0%;
    padding: unset;
  }

  .angebot-reitunterricht img {
    height: 210px;
    margin: 0 10px;
  }

  .angebot-einstellplatz {
    float: none;
    width: unset;
    padding-left: 0%;
    padding: unset;
  }

  .angebot-einstellplatz img {
    height: 210px;
    margin: 0 10px;
  }

  .unserangebot a {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
    color: black;
    border-width: 2px;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
    transition: 0.5s;
  }

  .unserangebot a:hover {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
    color: white;
    border-color: black;
    background-color: black;
    border-width: 2px;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
  }
  .maps .embed {
    width: 400px;
    height: 375px;
    border-radius: 10px;
    border-style: none;
    box-shadow: 4px 4px 10px rgb(145, 145, 145);
  }
}
