.formular input {
  border-radius: 3px;
  padding-bottom: 20px;
  font-size: 20px;
  border-style: none;
}
#nachrichtareaa {
  border-radius: 3px;
  padding-bottom: 225px;
  font-size: 10px;
  border-style: none;
  padding-right: 235px;
}
.formular h1 {
  padding-left: 65px;
}
.formular label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
}
.formular textarea {
  border-radius: 3px;
  padding-bottom: 20px;
  padding-right: 17px;
  border-style: none;
  font-size: 20px;
}
.form {
  padding-left: 70px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.address {
  padding-left: 325px;
  margin-top: -650px;
  padding-bottom: 100px;
}
.formular p {
  padding-left: 70px;
  padding-right: 300px;
  padding-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}
.address p {
  padding-left: 70px;
  padding-right: 300px;
  padding-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}
.address a {
  text-decoration: none;
  color: #000;
}
.address h3 {
  font-size: 30px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 550;
}
.formular h4 {
  font-size: 35px;
  font-weight: 100;
  margin-top: 215px;
  padding-left: 70px;
  font-family: "Times New Roman", Times, serif;
}
.formular a[type="submit"] {
  text-decoration: none;
  color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
  transition: 0.5s;
}

.formular a[type="submit"]:hover {
  text-decoration: none;
  color: white;
  border-color: black;
  background-color: black;
  border-width: 2px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-style: solid;
}

.required {
  color: red;
}

.requiredtext {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: -50px;
  font-style: italic;
}

@media (max-width: 812px) {
  #nachrichtareaa {
    border-radius: 3px;
    padding-bottom: 120px;
    font-size: 10px;
    border-style: none;
    padding-right: 120px;
  }
  .formular input {
    border-radius: 3px;
    padding-bottom: 20px;
    font-size: 10px;
    border-style: none;
    padding-right: 120px;
  }
  #nachrichtarea {
    border-radius: 3px;
    padding-bottom: 120px;
    font-size: 10px;
    border-style: none;
    padding-right: 120px;
  }
  .formular h1 {
    padding-left: 65px;
  }
  .formular label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
  }
  .form {
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
  .address {
    padding-left: 3px;
    margin-top: -20px;
    padding-bottom: 10px;
  }
  .formular p {
    padding-left: 70px;
    padding-right: 30px;
    padding-bottom: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 19px;
  }
  .address p {
    padding-left: 70px;
    padding-bottom: 30px;
    padding-top: 20px;
    text-align: unset;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 300;
  }
  .address a {
    text-decoration: none;
    color: #000;
  }
  .address h3 {
    font-size: 25px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 550;
  }
  .formular h4 {
    font-size: 35px;
    font-weight: 100;
    margin-top: -40px;
    margin-bottom: 0px;
    padding-left: 70px;
    font-family: "Times New Roman", Times, serif;
  }
  .formular a[type="submit"] {
    text-decoration: none;
    color: black;
    border-width: 2px;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
    transition: 0.5s;
  }

  .formular a[type="submit"]:hover {
    text-decoration: none;
    color: white;
    border-color: black;
    background-color: black;
    border-width: 2px;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-style: solid;
  }
  .requiredtext {
    margin-top: -10px;
    margin-bottom: -50px;
    padding-bottom: 10px;
    font-style: italic;
  }
}
