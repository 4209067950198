body {
  margin: 0;
}

@font-face {
  font-family: Muybridge;
  src: url(https://www.lorp.org/fonts/MuybridgeGX.woff2);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #d0b47c;
}

::-webkit-scrollbar-thumb {
  background: #b39a6a;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9b855c;
  border-radius: 5px;
}
